import { Controller } from 'stimulus';
import { regexes } from './lib';

const errorMessages = {
  pl: {
    fieldRequired: 'To pole jest wymagane',
    emailFormat: 'Nieprawidłowy format adresu email',
    captcha: 'Captcha nierozwiązana',
  },
  en: {
    fieldRequired: 'This field is required',
    emailFormat: 'This is not a valid email address',
    captcha: 'Captcha not solved',
  },
  nn: {
    fieldRequired: 'This field is required',
    emailFormat: 'This is not a valid email address',
    captcha: 'Captcha not solved',
  },
};

export default class extends Controller {
  static targets = ['form'];

  errorState = false;

  initialize() {
    this.isFormValid = false;
  }

  connect() {
    if (window.location.hash !== '') {
      this.removeHash();
    }
    // eslint-disable-next-line
    this.locale = window.location.pathname.split('/')[1];
  }

  removeHash = () =>
    window.history.pushState('', document.title, window.location.pathname + window.location.search);

  testEmail = val => regexes.email.test(val);

  displayErrorMessage = (wrapper, message) => {
    const error = document.createElement('span');
    const inputField =
      wrapper.querySelector('input') ||
      wrapper.querySelector('textarea') ||
      wrapper.querySelector('select');

    error.classList.add('error');
    error.innerHTML = message;
    wrapper.appendChild(error);
    inputField.classList.add('error');

    const customSelect = wrapper.querySelector('.custom-select-opener');

    if (customSelect) {
      customSelect.classList.add('error');
    }

    this.errorState = true;

    const errorContainer = this.formTarget.querySelector('.error-message');
    if (errorContainer) {
      errorContainer.classList.remove('invisible');
    }
  };

  removeErrorMessage = wrapper => {
    const inputField =
      wrapper.querySelector('input') ||
      wrapper.querySelector('textarea') ||
      wrapper.querySelector('select');

    inputField.classList.remove('error');
    const error = wrapper.querySelector('span.error:not(.custom-select-opener');
    if (error) {
      error.remove();
    }

    const customSelect = wrapper.querySelector('.custom-select-opener');

    if (customSelect) {
      customSelect.classList.remove('error');
    }

    const errorContainer = this.formTarget.querySelector('.error-message');

    if (!errorContainer) {
      return;
    }

    if (!this.areErrorsPresent()) {
      if (errorContainer) {
        errorContainer.classList.add('invisible');
      }
    }
  };

  areErrorsPresent = () => !!this.formTarget.querySelector('span.error');

  validateInput = (wrapper, condition, errorMessageCode, shouldSubmitBeDisabled = true) => {
    const inputField =
      wrapper.querySelector('input') ||
      wrapper.querySelector('textarea') ||
      wrapper.querySelector('select');

    const test = typeof condition === 'function' ? condition(inputField.value) : condition;

    if (test) {
      this.removeErrorMessage(wrapper);

      this.setSubmitDisabled(false);

      return true;
    }
    this.removeErrorMessage(wrapper);

    const errorMessage = errorMessages[this.locale || 'en'][errorMessageCode] || errorMessageCode;

    this.displayErrorMessage(wrapper, errorMessage);
    if (shouldSubmitBeDisabled) {
      this.setSubmitDisabled(true);
    }

    return false;
  };

  validateCaptcha = (wrapper, isCaptchaSolved) => {
    return this.validateInput(wrapper, isCaptchaSolved, 'captcha', false);
  };

  validateEmailInput = (e, userSelectedWrapper = null, displayErrorMessage = true) => {
    const wrapper = userSelectedWrapper || e.target.closest('.input-wrapper');
    // Don't display error when email is empty - user may want to fill it out later
    const isEmailValid = email => email === '' || this.testEmail(email);

    // Turns out we have to display an error message for empty email fields anyway - to be discussed further
    const isEmailNotEmpty = email => email !== '';

    const email = wrapper.querySelector('input').value;

    if (!isEmailValid(email)) {
      return this.validateInput(wrapper, isEmailValid, displayErrorMessage ? 'emailFormat' : '');
    }

    return this.validateInput(wrapper, isEmailNotEmpty, displayErrorMessage ? 'fieldRequired' : '');
  };

  validateEmailInputNoMessage = e => {
    this.validateEmailInput(e, null, false);
  };

  validateCheckbox = (wrapper, message) => {
    const isChecked = wrapper.querySelector('input').checked;
    return this.validateInput(wrapper, isChecked, message, false);
  };

  validateTextInput = e => {
    this.validateTextInputMain(e, 'fieldRequired');
  };

  validateTextInputNoMessage = e => {
    this.validateTextInputMain(e);
  };

  validateTextInputMain = (e, message = '') => {
    const wrapper = e.target.closest('.input-wrapper');
    const isTextNotEmpty = name => name !== '';

    this.validateInput(wrapper, isTextNotEmpty, message, false);
  };

  setSubmitDisabled = isDisabled => {
    const button = this.formTarget.querySelector('button');
    button.disabled = isDisabled;
  };

  handleDataLayer = formName => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'formSubmitted',
      formName,
    });
  };

  submitForm = (
    path,
    params,
    { onSuccess = () => null, onFail = () => null },
    attachThankYouToURL = true
  ) => {
    fetch(path, {
      method: 'post',
      body: params,
    })
      .then(res => {
        if (attachThankYouToURL) {
          window.location.hash = 'thank-you';
        }
        onSuccess();
        this.formTarget.reset();
        return res;
      })
      .catch(err => {
        // eslint-disable-next-line
        console.error('Request failed: ', err);
        onFail();
      });
  };
}
