import { Controller } from 'stimulus';

import { gsap, Power4, ScrollToPlugin } from 'gsap/all';

import ScrollMagic from 'scrollmagic';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';

const ANIMATION_DURATION = 0.6;

export default class extends Controller {
  static targets = ['section', 'description', 'image', 'imageWrapper', 'selectButton'];

  state = {
    activeCase: 0,
    isChanging: false,
    bufferedChange: null,
    animationDirection: 1,
    shouldScroll: false,
  };

  connect() {
    // eslint-disable-next-line
    const plugins = [ScrollToPlugin];
    this.activateSelectButton(0);
    this.setActiveItem(0);
    this.setupHeaderLock();
  }

  setupHeaderLock = () => {
    const headerController = this.application.getControllerForElementAndIdentifier(
      document.querySelector('.header'),
      'header'
    );
    const scrollController = new ScrollMagic.Controller();
    const scene = new ScrollMagic.Scene({
      duration: '10%',
      triggerElement: this.sectionTarget,
      triggerHook: 0,
    })
      .on('enter', headerController.lock)
      .on('leave', headerController.unlock)
      .addTo(scrollController);

    if (process.env.NODE_ENV === 'development') {
      scene.addIndicators();
    }
  };

  scrollToElement = () => {
    gsap.to(
      window,
      {
        duration: 0.4,
        scrollTo: {
          y: this.element,
        },
      },
      0
    );
  };

  activeImage = index =>
    this.imageTargets.find(
      target => parseInt(target.dataset.id, 10) === (index == null ? this.state.activeCase : index)
    );

  buttonAt = index =>
    this.selectButtonTargets.find(target => parseInt(target.dataset.id, 10) === index);

  activeButton = () => this.selectButtonTargets.find(button => button.classList.contains('active'));

  activeDescription = index =>
    this.descriptionTargets.find(
      target => parseInt(target.dataset.id, 10) === (index == null ? this.state.activeCase : index)
    );

  activateSelectButton = index => {
    this.deactivateTargets('selectButton');
    this.buttonAt(index).classList.add('active');
  };

  deactivateTargets = targetName => {
    this[`${targetName}Targets`].forEach(button => button.classList.remove('active'));
  };

  setActiveItem = newCase => {
    if (this.state.isChanging) {
      this.setState({ bufferedChange: newCase });
      return;
    }

    const { activeCase: oldCase } = this.state;

    this.setState({ isChanging: true });
    this.deactivateTargets('description');
    this.deactivateTargets('image');
    this.animate(oldCase, newCase);
  };

  toggleCase = e => {
    const targetItem = parseInt(e.target.dataset.id, 10);

    const animationDirection =
      Math.sign(targetItem - parseInt(this.activeButton().dataset.id, 10)) || 1;
    this.scrollToElement();
    this.setState({ animationDirection, shouldScroll: true });
    this.activateSelectButton(targetItem);
    this.setActiveItem(targetItem);
  };

  togglePrevCase = () => this.toggleCaseByButton(-1);

  toggleNextCase = () => this.toggleCaseByButton(1);

  toggleCaseByButton = dir => {
    const activeButtonId = parseInt(this.activeButton().dataset.id, 10);
    const targetItem =
      dir === 1 ? this.nextCaseIndex(activeButtonId) : this.prevCaseIndex(activeButtonId);

    this.setState({ animationDirection: dir, shouldScroll: true });
    this.activateSelectButton(targetItem);

    if (this.state.isChanging) {
      this.setState({
        bufferedChange: targetItem,
      });
      return;
    }
    this.scrollToElement();
    this.setActiveItem(targetItem);
  };

  prevCaseIndex = index =>
    (index - 1 + this.selectButtonTargets.length) % this.selectButtonTargets.length;

  nextCaseIndex = index => (index + 1) % this.selectButtonTargets.length;

  setState = newState => {
    this.state = { ...this.state, ...newState };
  };

  animate = (oldCase, newCase) => {
    const { animationDirection } = this.state;
    const tl = gsap.timeline();

    const delay = 0.12;

    tl.fromTo(
      this.activeImage(oldCase),
      ANIMATION_DURATION,
      { x: '0%', opacity: 1 },
      {
        ease: Power4.easeInOut,
        x: `${-animationDirection * 200}%`,

        onComplete: () => {
          this.setState({ activeCase: newCase });
        },
      },
      animationDirection === 1 ? delay : 0
    )
      .fromTo(
        this.activeImage(newCase),
        ANIMATION_DURATION,
        {
          x: `${animationDirection * 200}%`,
          opacity: 1,
        },
        {
          x: '0%',
          ease: Power4.easeInOut,

          onComplete: () => {
            this.setState({ isChanging: false, shouldScroll: false });
            const { bufferedChange } = this.state;

            if (bufferedChange != null) {
              this.setState({ bufferedChange: null });
              this.setActiveItem(bufferedChange);
            }
          },
        },
        animationDirection === 1 ? delay : 0
      )
      .to(
        this.element,
        ANIMATION_DURATION / 2,
        {
          onComplete: () => {
            this.imageWrapperTarget.style.backgroundColor = this.activeImage(
              newCase
            ).dataset.bgcolor;
            this.activeImage(newCase).classList.add('active');
            this.activeDescription(newCase).classList.add('active');
          },
        },
        0
      )
      .fromTo(
        this.activeDescription(oldCase),
        ANIMATION_DURATION,
        { x: '-50%', y: '-50%' },
        {
          ease: Power4.easeInOut,
          x: `${-animationDirection * 200}%`,
          y: '-50%',
        },
        animationDirection === 1 ? 0 : delay
      )
      .fromTo(
        this.activeDescription(newCase),
        ANIMATION_DURATION,
        {
          x: `${animationDirection * 200}%`,
          y: '-50%',
          opacity: 1,
        },
        {
          x: '-50%',
          y: '-50%',
          ease: Power4.easeInOut,
        },
        animationDirection === 1 ? 0 : delay
      );
  };
}
