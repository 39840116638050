import logoLight from 'images/home/logo-lightbg.svg';
import logoDarkbg from 'images/home/logo-darkbg.svg';
import logoColorbg from 'images/home/logo-colorbg.svg';
import logoMonoBlack from 'images/home/logo-mono-black.svg';
import logoStartupGrindLight from 'images/home/startup-grind-with-divider-light.svg';
import logoStartupGrindDark from 'images/home/startup-grind-with-divider-dark.svg';
import { remToPx } from 'shared/lib';
import { Power2 } from 'gsap/all';

export const grid = {
  breakXs: 480,
  breakSm: 576,
  breakMd: 768,
  breakLg: 992,
  breakXl: 1200,
  breakXxl: 1600,
  breakXxxl: 1920,
};

export const themes = {
  white: {
    languageButtonBackgroundColor: 'transparent',
    languageButtonTextColor: '#a0a5b2',
    valueButtonBackgroundColor: '#0066ff',
    valueButtonTextColor: '#fff',
    languageArrow: '#636872',
    backgroundColor: '#fff',
    textColor: '#040015',
    arrowColor: '#0066ff',
    borderColor: '#0066ff',
    logo: logoLight,
    logoStartupGrind: logoStartupGrindDark,
  },
  black: {
    languageButtonBackgroundColor: 'transparent',
    languageButtonTextColor: '#a0a5b2',
    valueButtonBackgroundColor: '#0066ff',
    valueButtonTextColor: '#fff',
    languageArrow: '#fff',
    backgroundColor: '#04080f',
    textColor: '#fff',
    arrowColor: '#0066ff',
    borderColor: '#0066ff',
    logo: logoDarkbg,
    logoStartupGrind: logoStartupGrindLight,
  },
  gray: {
    languageButtonBackgroundColor: 'transparent',
    languageButtonTextColor: '#a0a5b2',
    valueButtonBackgroundColor: '#0066ff',
    languageArrow: '#636872',
    valueButtonTextColor: '#fff',
    backgroundColor: '#f8fafd',
    textColor: '#040015',
    arrowColor: '#0066ff',
    borderColor: '#0066ff',
    logo: logoLight,
    logoStartupGrind: logoStartupGrindDark,
  },
  lightGray: {
    languageButtonBackgroundColor: 'transparent',
    languageButtonTextColor: '#a0a5b2',
    valueButtonBackgroundColor: '#0066ff',
    languageArrow: '#636872',
    valueButtonTextColor: '#fff',
    backgroundColor: '#F7F8FA',
    textColor: '#000000',
    arrowColor: '#0066ff',
    borderColor: '#0066ff',
    logo: logoLight,
    logoStartupGrind: logoStartupGrindDark,
  },
  color: {
    languageButtonBackgroundColor: 'transparent',
    languageButtonTextColor: '#3384ff',
    valueButtonBackgroundColor: '#fff',
    valueButtonTextColor: '#040015',
    languageArrow: '#fff',
    backgroundColor: '#0066ff',
    textColor: '#fff',
    arrowColor: '#fff',
    borderColor: '#fff',
    logo: logoColorbg,
    logoStartupGrind: logoStartupGrindLight,
  },
  navy: {
    languageButtonBackgroundColor: 'transparent',
    languageButtonTextColor: '#3384ff',
    valueButtonBackgroundColor: '#fff',
    valueButtonTextColor: '#040015',
    languageArrow: '#fff',
    backgroundColor: '#131a42',
    textColor: '#fff',
    arrowColor: '#fff',
    borderColor: '#fff',
    logo: logoColorbg,
    logoStartupGrind: logoStartupGrindDark,
  },
  monoBaseBlack: {
    languageButtonBackgroundColor: 'transparent',
    languageButtonTextColor: '#a0a5b2',
    valueButtonBackgroundColor: '#000000',
    valueButtonTextColor: '#fff',
    languageArrow: '#636872',
    backgroundColor: 'transparent',
    textColor: '#000000',
    arrowColor: '#000000',
    borderColor: '#000000',
    logo: logoMonoBlack,
    logoStartupGrind: logoStartupGrindDark,
  },
  monoBaseWhite: {
    languageButtonBackgroundColor: 'transparent',
    languageButtonTextColor: '#fff',
    valueButtonBackgroundColor: '#fff',
    valueButtonTextColor: '#04080f',
    languageArrow: '#fff',
    backgroundColor: 'transparent',
    textColor: '#fff',
    arrowColor: '#fff',
    borderColor: '#fff',
    logo: logoColorbg,
    logoStartupGrind: logoStartupGrindLight,
  },
};

const HIDDEN_TEXT_POSITION = -200;

const getActiveSectionHeight = () => {
  const width = window.innerWidth;

  if (width > grid.breakXxl) {
    return (window.innerHeight * 4) / 10;
  }
  if (width > grid.breakXl) {
    return (window.innerHeight * 3.92) / 10;
  }
  if (width > grid.breakMd) {
    return (window.innerHeight * 3.8) / 10;
  }
  if (width > grid.breakSm) {
    return (window.innerHeight * 4.2) / 10;
  }

  return (window.innerHeight * 3.5) / 10;
};
const INACTIVE_SECTION_HEIGHT = window.innerWidth > grid.breakXxl ? 60 : 40;

export const DEFAULT_EASE = Power2.easeInOut;

export const DEFAULT_ANIM_TIME = 0.8;

export const animParams = {
  caseStudy: {
    active: {
      height: getActiveSectionHeight(),
      ease: DEFAULT_EASE,
      opacity: 1,
    },
    inactive: {
      height: INACTIVE_SECTION_HEIGHT,
      ease: DEFAULT_EASE,
    },
    hideInactive: {
      height: 0,
      ease: DEFAULT_EASE,
    },
  },
  caseStudyTitle: {
    active: {
      scale: 1,
      opacity: 1,
      ease: DEFAULT_EASE,
    },
    inactive: {
      scale: 0.8,
      opacity: 0.2,
      ease: DEFAULT_EASE,
    },
    hideInactive: {
      scale: 0,
      ease: DEFAULT_EASE,
    },
  },
  caseStudyBody: {
    active: {
      y: 0,
      ease: DEFAULT_EASE,
    },
    inactive: {
      y: HIDDEN_TEXT_POSITION,
      ease: DEFAULT_EASE,
    },
    hideInactive: {
      y: HIDDEN_TEXT_POSITION,
      ease: DEFAULT_EASE,
    },
  },
  caseStudyButton: {
    active: {
      opacity: 1,
      y: 0,
      ease: DEFAULT_EASE,
      pointerEvents: 'all',
    },
    inactive: {
      opacity: 0,
      y: HIDDEN_TEXT_POSITION,
      ease: DEFAULT_EASE,
      pointerEvents: 'none',
    },
    hideInactive: {
      opacity: 0,
      y: HIDDEN_TEXT_POSITION,
      ease: DEFAULT_EASE,
      pointerEvents: 'none',
    },
  },
  caseStudyNumber: {
    active: {
      y: 0,
      opacity: 1,
      ease: DEFAULT_EASE,
    },
    inactive: {
      y: 30,
      opacity: 0,
      ease: DEFAULT_EASE,
    },
    hideInactive: {
      y: 30,
      opacity: 0,
      ease: DEFAULT_EASE,
    },
  },
};

export const GRID_PADDING = remToPx(1);
export const CONTENT_WIDTH_IN_REM = 86;
export const CONTENT_WIDTH_IN_PX = remToPx(CONTENT_WIDTH_IN_REM);

export const ENDPOINTS = {
  careersBlog: '/api/articles/about_us',
  latestPosts: '/api/articles/latest',
  companyValues: '/api/company_values',
};
