import { Controller } from 'stimulus';

const ACTIVE_CLASS = 'is-active';

export default class extends Controller {
  static targets = ['card'];

  onCardClickHandler = e => {
    if (e.target.classList.contains(ACTIVE_CLASS)) {
      e.target.classList.remove(ACTIVE_CLASS);
    } else {
      this.cardTargets.forEach(card => card.classList.remove(ACTIVE_CLASS));
      e.target.classList.add(ACTIVE_CLASS);
    }
  };
}
