import { Controller } from 'stimulus';
import fileTemplate from './file_template/file_template';

export default class extends Controller {
  static targets = ['filesContainer', 'fileInput'];

  deleteFile = e => {
    const { fileindex } = e.target.dataset;

    // FileList is not an array, so it has to be converted
    const fileArray = Array.from(this.fileInputTarget.files);
    fileArray.splice(fileindex, 1);

    // A sneaky way of removing a file - since filelist has no constructor,
    // we create a data transfer event to create a new file list and then
    // swap the input filelist for this one
    const dt = new ClipboardEvent('').clipboardData || new DataTransfer();
    fileArray.forEach(file => dt.items.add(file));
    this.fileInputTarget.files = dt.files;

    // Finally, we refresh the list of attachments
    this.populateFilesContainer();
  };

  populateFilesContainer = () => {
    let filesComponents = '';
    Array.from(this.fileInputTarget.files).forEach((file, index) => {
      filesComponents += fileTemplate(file.name, index);
    });

    this.filesContainerTarget.innerHTML = filesComponents;
  };
}
