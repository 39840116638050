export const regexes = {
  // eslint-disable-next-line
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const range = x => [...Array(x).keys()];

export const remToPx = rem => rem * parseFloat(getComputedStyle(document.documentElement).fontSize);

export const toggleArrayElement = (array, value) => {
  const index = array.indexOf(value);
  const updatedArray = [...array];

  if (index === -1) {
    updatedArray.push(value);
  } else {
    updatedArray.splice(index, 1);
  }

  return updatedArray;
};

export const parseDatasetIntegers = dataset => {
  if (typeof dataset !== 'object' || !Object.keys(dataset).length) {
    return {};
  }

  return Object.keys(dataset).reduce((acc, field) => {
    let parsedValue = dataset[field];

    try {
      parsedValue = parseInt(dataset[field], 10);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    return {
      ...acc,
      [field]: parsedValue,
    };
  });
};

export const formatNumberWithDots = number =>
  number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

export const formatNumberWithSpace = number =>
  number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

export class Swipeable {
  constructor(
    rootNode,
    {
      onSwipedLeft = () => null,
      onSwipedRight = () => null,
      deadZone = 10,
      maximumVerticalMove = 50,
      swipeOnMouseDrag = false,
    }
  ) {
    this.state = { startX: 0, startY: 0 };
    this.props = {
      rootNode,
      onSwipedLeft,
      onSwipedRight,
      deadZone,
      maximumVerticalMove,
      swipeOnMouseDrag,
    };

    rootNode.addEventListener('touchstart', this.handleTouchStart, { passive: true });
    rootNode.addEventListener('touchend', this.handleTouchEnd);
    if (swipeOnMouseDrag) {
      rootNode.addEventListener('mousedown', this.handleMouseDown);
      rootNode.addEventListener('mouseup', this.handleMouseUp);
    }
  }

  handleMouseDown = e => {
    e.preventDefault();
    this.handleTouchStart(e, true);
  };

  handleMouseUp = e => {
    e.preventDefault();
    this.handleTouchEnd(e, true);
  };

  handleTouchStart = (e, isMouseDrag) => {
    this.state.startX = isMouseDrag ? e.clientX : e.changedTouches[0].clientX;
    this.state.startY = isMouseDrag ? e.clientY : e.changedTouches[0].clientY;
  };

  handleTouchEnd = (e, isMouseDrag) => {
    const { startX, startY } = this.state;
    const { onSwipedLeft, onSwipedRight, deadZone, maximumVerticalMove } = this.props;
    const endX = isMouseDrag ? e.clientX : e.changedTouches[0].clientX;
    const endY = isMouseDrag ? e.clientY : e.changedTouches[0].clientY;

    const isSwipeRegistered =
      Math.abs(startX - endX) > deadZone && Math.abs(startY - endY) < maximumVerticalMove;
    const isSwipeLeft = isSwipeRegistered && endX < startX;
    const isSwipeRight = isSwipeRegistered && endX > startX;

    isSwipeLeft && onSwipedLeft();
    isSwipeRight && onSwipedRight();
  };

  // if for some reason you need to call the swipe callbacks manually...
  swipeLeft = () => this.props.onSwipedLeft();

  swipeRight = () => this.props.onSwipedRight();

  remove = () => {
    const { rootNode } = this.props;
    rootNode.removeEventListener('touchstart', this.handleTouchStart);
    rootNode.removeEventListener('touchend', this.handleTouchStart);
    rootNode.removeEventListener('mousedown', this.handleMouseDown);
    rootNode.removeEventListener('mouseup', this.handleMouseUp);
  };
}

// Detects when the user stopped scrolling and passes scroll direction as 'down' or 'up' to the callback
// accepts a callback and delay in milliseconds as parameters
export const onScrollStop = (callback = () => null, delay = 66) => {
  let isScrolling;
  let lastScrollTop = 0;
  window.addEventListener('scroll', () => {
    const dir = document.documentElement.scrollTop > lastScrollTop ? 'down' : 'up';
    lastScrollTop = document.documentElement.scrollTop;
    clearTimeout(isScrolling);
    isScrolling = setTimeout(() => {
      if (typeof callback === 'function') {
        callback(dir);
      }
    }, delay);
  });
};

export const randomElement = arr => arr[Math.floor(Math.random() * arr.length)];

function hexToRgb(col) {
  let usePound = false;

  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);

  /* eslint-disable */
  const r = num >> 16;

  const b = (num >> 8) & 0x00ff;

  const g = num & 0x0000ff;

  return { r, g, b, usePound };
  /* eslint-enable */
}

export function lightenDarkenColor(col, amt) {
  // eslint-disable-next-line
  let { r, g, b, usePound } = hexToRgb(col);

  // For very light or very dark backgrounds we need to tweak this amount
  if (r < 40 || g < 40 || b < 40) {
    amt += 50;
  } else if (r > 200 && g > 200 && b > 200) {
    amt -= 100;
  }

  r += amt;
  g += amt;
  b += amt;
  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }

  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }

  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }
  // eslint-disable-next-line
  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export function shuffle(array) {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  const shuffledArray = [...array];
  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = shuffledArray[currentIndex];
    shuffledArray[currentIndex] = shuffledArray[randomIndex];
    shuffledArray[randomIndex] = temporaryValue;
  }

  return shuffledArray;
}

export function appendUtmToQueryParam() {
  const utmsNames = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'source', 'fbclid'];
  const utmQueryParams = [];

  utmsNames.forEach(utmName => {
    const utmValue = sessionStorage.getItem(utmName);

    if (utmValue) {
      utmQueryParams.push(`${utmName}=${utmValue}`);
    }
  });

  if (utmQueryParams.length > 0) {
    window.history.replaceState(null, null, `?${utmQueryParams.join('&')}`);
  }
}

export function addFacebookPixelCustomEvent(name, message = 'FormSubmit') {
  const pixelEvent = `${message}: ${name}`;

  if (window.fbq) {
    window.fbq('trackCustom', pixelEvent);
  } else {
    console.error(`${pixelEvent}: window.fbq is not defined`);
  }
}
