import { TimelineLite } from 'gsap/all';

import FormController from 'shared/form_controller';
import { addFacebookPixelCustomEvent } from 'shared/lib';

export default class extends FormController {
  static targets = ['form', 'email', 'descriptionPart', 'firstPart', 'lastPart', 'submitButton'];

  onSubmit = e => {
    e.preventDefault();

    if (!this.validateInput(this.emailTarget, email => email !== '', 'fieldRequired')) {
      return;
    }

    if (this.submitButtonTarget.classList.contains('checked')) {
      return;
    }

    const onSuccess = () => {
      this.displayMessageSent;
      this.handleDataLayer('Design');
      addFacebookPixelCustomEvent('ContactForm');
    };

    const formData = new FormData();
    formData.append('email', this.emailTarget.querySelector('input').value);
    formData.append('content', '');
    formData.append('name', '');
    formData.append('validate_hcaptcha', 'false');

    this.submitForm('/api/messages', formData, { onSuccess });
  };

  displayMessageSent = () => {
    const submitButtonLabel = this.submitButtonTarget.querySelector('.text');
    const submitButtonArrowShaft = this.submitButtonTarget.querySelector('.arrow-right');
    const tl = new TimelineLite();
    tl.to(this.firstPartTarget, 0.4, {
      opacity: 0,
    })
      .to(this.descriptionPartTargets, 0.4, {
        y: '-100%',
      })
      .to(this.lastPartTarget, 0.4, {
        opacity: 1,
      })
      .to(
        this.emailTarget,
        0.4,
        {
          width: 0,
          margin: 0,
          opacity: 0,
        },
        0
      )
      .to(
        this.submitButtonTarget,
        0.4,
        {
          backgroundColor: '#00d152',
          onStart() {
            this.target.classList.add('checked');
          },
        },
        0
      )
      .to(
        submitButtonLabel,
        0.2,
        {
          opacity: 0,
          onComplete() {
            this.target.textContent = 'Sent';
            submitButtonArrowShaft.classList.add('checked');
          },
        },
        0
      )
      .to(
        submitButtonLabel,
        0.2,
        {
          opacity: 1,
        },
        0.2
      );
  };
}
