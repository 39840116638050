import { Controller } from 'stimulus';
import { gsap, Power1 } from 'gsap/all';
import throttle from 'lodash-es/throttle';
import imagesLoaded from 'imagesloaded';
import { Swipeable, remToPx } from 'shared/lib';
import { CONTENT_WIDTH_IN_PX } from 'shared/constants';

export default class extends Controller {
  static targets = ['slider', 'sliderScrollWrapper'];

  state = { currentSlide: 2, numberOfSlides: 0 };

  connect() {
    this.state.numberOfSlides = this.sliderTarget.children.length;
    this.sliderTarget.children[this.state.currentSlide].classList.add('active');

    this.swipeable = new Swipeable(this.sliderScrollWrapperTarget, {
      onSwipedLeft: this.toggleNextSlide,
      onSwipedRight: this.togglePrevSlide,
      swipeOnMouseDrag: true,
    });

    imagesLoaded(document, () => this.displayCurrentSlide());
    window.addEventListener('resize', this.displayCurrentSlide);
  }

  disconnect() {
    window.removeEventListener('resize', this.displayCurrentSlide);
    this.swipeable.remove();
  }

  toggleNextSlide = () => {
    const { currentSlide, numberOfSlides } = this.state;
    this.state.currentSlide = currentSlide < numberOfSlides - 1 ? currentSlide + 1 : 0;
    this.displayCurrentSlide();
  };

  togglePrevSlide = () => {
    const { currentSlide, numberOfSlides } = this.state;
    this.state.currentSlide = currentSlide > 0 ? currentSlide - 1 : numberOfSlides - 1;
    this.displayCurrentSlide();
  };

  displayCurrentSlide = throttle(() => {
    const { currentSlide } = this.state;
    const slideWidth = this.sliderTarget.children[0].getBoundingClientRect().width;
    const viewWidth =
      CONTENT_WIDTH_IN_PX > window.innerWidth ? window.innerWidth : CONTENT_WIDTH_IN_PX;
    const gapBetweenSlides = remToPx(4);

    gsap.to(this.sliderTarget, {
      duration: 0.4,
      x: -currentSlide * (slideWidth + gapBetweenSlides) - slideWidth / 2 + viewWidth / 2,
      ease: Power1.easeInOut,
    });

    Array.from(this.sliderTarget.children).forEach((slide, index) => {
      if (index === currentSlide) {
        slide.classList.add('active');
      } else {
        slide.classList.remove('active');
      }
    });
  }, 66);
}
