import { Controller } from 'stimulus';
import throttle from 'lodash-es/throttle';
import debounce from 'lodash-es/debounce';
import { CSSPlugin, Linear, gsap } from 'gsap/all';

import { grid } from 'shared/constants';

export default class extends Controller {
  static targets = [
    'header',
    'background',
    'logo',
    'logoMask',
    'menu',
    'toggle',
    'navItem',
    'valueProjectButton',
    'subnav',
    'chevronDown',
    'langSelect',
    'startupGrindLogo',
    'dropdownContent',
    'arrow',
    'label',
  ];

  initialize() {
    // Tree shaking and GSAP plugins doesn't cooperate well
    // eslint-disable-next-line
    const plugins = [CSSPlugin];

    this.toggleMargin = 100;
    this.tl = gsap.timeline();
    this.state = {
      isOpen: false,
      scrollUpStart: null,
      scrollDownStart: null,
      rocketPositionChanged: false,
      isLocked: false,
      isRocketHidden: false,
    };
    this.throttledMenuByScroll = throttle(this.toggleMenuByScroll, 100);
    this.debouncedMenuByWindowResize = debounce(this.toggleMenuState, 10);

    window.addEventListener('scroll', this.throttledMenuByScroll);
    window.addEventListener('resize', this.debouncedMenuByWindowResize);
    this.updateLanguageLabel();
  }

  connect() {
    this.footerLogo = document.querySelector('#footer-logo');
    if (!this.isMobile()) {
      this.openMenu();
    }
  }

  disconnect() {
    this.remove();
  }

  isMobile = () => window.innerWidth < grid.breakXl;

  toggleMenuState = () => {
    this.closeMenu();
    if (this.isMobile()) {
      this.openTopBar();
    } else {
      this.openMenu();
    }
  };

  openTopBar = () => {
    if (!this.logoTarget.classList.contains('logo--hidden')) {
      this.backgroundTarget.classList.remove('hidden');
    }
  };

  closeTopBar = () => {
    this.backgroundTarget.classList.add('hidden');
  };

  openMenu = () => {
    const { isOpen, rocketPositionChanged } = this.state;

    if (!isOpen) {
      this.headerTarget.classList.add('active');
      this.backgroundTarget.classList.remove('hidden');
      this.backgroundTarget.classList.add('open');
      this.menuTarget.classList.remove('hidden');

      if (this.hasValueProjectButtonTarget) {
        this.valueProjectButtonTarget.classList.remove('hidden');
      }

      this.toggleTarget.classList.add('active');
      this.langSelectTarget.classList.remove('hidden');
      this.state.isOpen = true;

      if (this.isMobile()) {
        this.disableScrollOnBody();
        this.logoTarget.classList.add('small');
        this.logoMaskTarget.classList.add('wide');
        if (!rocketPositionChanged) {
          this.animateRocketPosition();
        }
      }
    }

    if (rocketPositionChanged && !this.isMobile()) {
      this.restoreRocketPosition();
    }
  };

  closeMenu = () => {
    const { isOpen, rocketPositionChanged } = this.state;

    if (isOpen) {
      this.headerTarget.classList.remove('active');
      this.backgroundTarget.classList.remove('open');
      this.menuTarget.classList.add('hidden');

      if (this.hasValueProjectButtonTarget) {
        this.valueProjectButtonTarget.classList.add('hidden');
      }

      this.toggleTarget.classList.remove('active');
      this.langSelectTarget.classList.add('hidden');
      this.state.isOpen = false;

      this.enableScrollOnBody();

      if (!this.isMobile()) {
        this.backgroundTarget.classList.add('hidden');
        this.animateRocketPosition();
        return;
      }

      if (!rocketPositionChanged) {
        this.logoTarget.classList.remove('small');
        this.logoMaskTarget.classList.remove('wide');
      }

      this.closeTopBar();
      this.closeAllSubmenus();
    }
  };

  restoreRocketPosition = () => {
    this.state.rocketPositionChanged = false;
    let newXPos = '+=16';

    if (this.isMobile()) {
      newXPos = '+=52';
    }
    this.tl
      .to(this.logoTarget, this.isMobile() ? 0.23 : 0.33, {
        rotation: 15,
        x: '+=16',
        transformOrigin: '18px 50%',
        ease: Linear.easeNone,
        delay: this.isMobile() ? 0.1 : 0.2,
      })
      .to(this.logoTarget, this.isMobile() ? 0.43 : 0.53, {
        x: newXPos,
        rotation: 0,
        onComplete: () => {
          this.logoTarget.classList.remove('small');
          this.logoMaskTarget.classList.remove('wide');
          this.showStartupGrindLogo();
        },
      });
  };

  animateRocketPosition = () => {
    let newXPos = -34;
    this.state.rocketPositionChanged = true;

    if (window.innerWidth >= grid.breakXxl) {
      newXPos = -36;
    } else if (window.innerWidth < grid.breakXl) {
      newXPos = -32;
    }

    if (this.isMobile()) {
      newXPos = -70;
    }

    this.tl
      .to(this.logoTarget, 0.33, {
        rotation: -15,
        x: -16,
        transformOrigin: '18px 50%',
        ease: Linear.easeNone,
        delay: 0.1,
        onStart: () => {
          this.logoTarget.classList.add('small');
          this.logoMaskTarget.classList.add('wide');
        },
      })
      .to(this.logoTarget, 0.53, {
        x: newXPos,
        rotation: 0,
      });
  };

  hideRocket = () => {
    this.state.isRocketHidden = true;

    if (!this.logoTarget.classList.contains('logo--hidden')) {
      this.tl.kill();
      this.tl.to(this.logoTarget, 0.3, {
        y: -100,
      });
      this.logoTarget.classList.add('logo--hidden');
      if (this.isMobile()) {
        this.closeTopBar();
      }
    }
  };

  hideStartupGrindLogo = () => {
    this.startupGrindLogoTarget.classList.add('hidden');
  };

  showStartupGrindLogo = () => {
    this.startupGrindLogoTarget.classList.remove('hidden');
  };

  showRocket = () => {
    this.state.isRocketHidden = false;

    if (this.logoTarget.classList.contains('logo--hidden')) {
      this.tl.kill();
      this.tl.to(this.logoTarget, 0.3, {
        y: 0,
      });
      this.logoTarget.classList.remove('logo--hidden');
    }
  };

  toggleMenuByButton = () => {
    this.state.scrollDownStart = null;
    this.state.scrollUpStart = null;

    if (this.state.isOpen) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  };

  shouldBlockMenuToggle = () => {
    return window.location.href.includes('startup-grind') && !this.isMobile();
  };

  toggleMenuByScroll = () => {
    this.dropdownContentTarget.classList.remove('show');
    this.arrowTarget.classList.remove('arrow-down');

    if (this.shouldBlockMenuToggle()) {
      return;
    }

    const { isOpen, isLocked, scrollDownStart, scrollUpStart, rocketPositionChanged } = this.state;
    const shouldPreventMenuCloseOnMobile = this.isMobile() && isOpen;
    if (shouldPreventMenuCloseOnMobile) {
      return;
    }

    // check if header has been externally prevented from opening on scroll (e.g. by the rotator),
    if (isLocked) {
      this.closeMenu();
      return;
    }

    // check if we are at the bottom of the page or is page logo is on the same position like footer logo
    if (this.shouldRocketBeHidden()) {
      this.hideRocket();
    } else if (!isOpen || !this.isMobile()) {
      this.showRocket();
    }

    if (this.oldScrollY < window.scrollY) {
      // Scroll down
      this.state.scrollUpStart = null;
      this.oldScrollY = window.scrollY;

      if (scrollDownStart === null) {
        this.state.scrollDownStart = window.scrollY;
      }

      if (window.scrollY > scrollDownStart + this.toggleMargin) {
        if (this.isMobile()) {
          this.hideStartupGrindLogo();
          !rocketPositionChanged && !isOpen && this.animateRocketPosition();
          this.closeTopBar();
        }
        this.closeMenu();
      }
    } else {
      // Scroll up
      this.state.scrollDownStart = null;
      this.oldScrollY = window.scrollY;

      if (scrollUpStart === null) {
        this.state.scrollUpStart = window.scrollY;
      }

      if (window.scrollY < scrollUpStart - this.toggleMargin) {
        if (this.isMobile()) {
          rocketPositionChanged && this.restoreRocketPosition();
          isOpen && this.closeMenu();
          this.openTopBar();
          return;
        }
        this.openMenu();
      }
    }
  };

  toggleSubmenu = e => {
    e.preventDefault();

    if (!this.isMobile()) {
      return;
    }

    const { target } = e;

    const navItem = target.closest('.nav__item');
    const subnav = navItem.querySelector('.subnav');
    const arrow = navItem.querySelector('.chevron-down');
    this.closeOtherSubmenus(subnav, arrow);
    arrow.classList.toggle('arrow-up');
    subnav.classList.toggle('visible');
  };

  closeOtherSubmenus = (subnav, arrow) => {
    this.subnavTargets.forEach(sub => {
      if (sub !== subnav) {
        sub.classList.remove('visible');
      }
    });
    this.chevronDownTargets.forEach(arr => {
      if (arr !== arrow) {
        arr.classList.remove('arrow-up');
      }
    });
  };

  closeAllSubmenus = () => {
    this.subnavTargets.forEach(subnav => subnav.classList.remove('visible'));
    this.chevronDownTargets.forEach(arrow => arrow.classList.remove('arrow-up'));
  };

  isBottomOfPage = () => window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;

  isHeaderLogoOnFooter = () => {
    const footerLogoPos =
      this.footerLogo.getBoundingClientRect().top + document.documentElement.scrollTop;
    const headerLogoPos =
      this.logoTarget.getBoundingClientRect().top + document.documentElement.scrollTop;

    // we need to account for the rocket hiding and changing its position and then appearing again
    return headerLogoPos + (this.state.isRocketHidden ? 220 : 120) > footerLogoPos;
  };

  shouldRocketBeHidden = () => {
    if (this.footerLogo) {
      return this.isBottomOfPage() || this.isHeaderLogoOnFooter();
    }

    return this.isBottomOfPage();
  };

  lock = () => {
    if (!this.state.isLocked) {
      this.state.isLocked = true;
    }
  };

  unlock = () => {
    if (this.state.isLocked) {
      this.state.isLocked = false;
    }
  };

  remove = () => {
    window.removeEventListener('scroll', this.throttledMenuByScroll);
    window.removeEventListener('resize', this.debouncedMenuByWindowResize);
  };

  toggleLanguageMenu = () => {
    this.dropdownContentTarget.classList.toggle('show');
    this.dropdownContentTarget.classList.contains('show')
      ? this.arrowTarget.classList.add('arrow-down')
      : this.arrowTarget.classList.remove('arrow-down');
  };

  updateLanguageLabel = () => {
    const currLang = window.location.pathname.split('/')[1] || 'en';

    if (currLang === 'en') {
      this.labelTarget.innerText = 'EN';
    } else if (currLang === 'nn') {
      this.labelTarget.innerText = 'NO';
    } else if (currLang === 'pl') {
      this.labelTarget.innerText = 'PL';
    }
  };

  disableScrollOnBody = () => {
    document.querySelector('body').style.overflowY = 'hidden';
  };

  enableScrollOnBody = () => {
    document.querySelector('body').style.overflowY = 'visible';
  };
}
