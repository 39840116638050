import { Controller } from 'stimulus';
import { Swipeable } from 'shared/lib';
import imagesLoaded from 'imagesloaded';
import throttle from 'lodash-es/throttle';
import { grid } from 'shared/constants';

export default class extends Controller {
  static targets = [
    'swipeContainer',
    'sliderMain',
    'slide',
    'thumbnail',
    'thumbnailsWrapper',
    'count',
    'desktopFooter',
  ];

  connect() {
    this.setup();
  }

  disconnect() {
    this.swipeable.remove();
    window.removeEventListener('resize', this.onWindowResize);
  }

  setup() {
    this.currentSlide = 0;
    this.swipeable = new Swipeable(this.swipeContainerTarget, {
      onSwipedLeft: this.toggleNextSlide,
      onSwipedRight: this.togglePrevSlide,
    });
    imagesLoaded(this.element, () => {
      this.displayActiveSlide();
      this.setSliderHeight();
    });

    window.addEventListener('resize', this.onWindowResize);
  }

  setSliderHeight = () => {
    const maxSlideHeight = Math.max(
      ...this.slideTargets.map(slide => slide.getBoundingClientRect().height)
    );
    this.sliderMainTarget.style.height = `${maxSlideHeight}px`;
  };

  togglePrevSlide = () => {
    this.currentSlide =
      this.currentSlide > 0 ? this.currentSlide - 1 : this.slideTargets.length - 1;
    this.displayActiveSlide();
  };

  toggleNextSlide = () => {
    this.currentSlide =
      this.currentSlide < this.slideTargets.length - 1 ? this.currentSlide + 1 : 0;
    this.displayActiveSlide();
  };

  toggleSlide = e => {
    this.currentSlide = this.thumbnailTargets.indexOf(e.target);
    this.displayActiveSlide();
  };

  displayActiveSlide = () => {
    this.slideTargets.forEach((slide, index) => {
      if (index === this.currentSlide) {
        slide.classList.add('active');
        this.thumbnailTargets[index].classList.add('active');
        this.desktopFooterTargets[index].classList.add('active');
      } else {
        slide.classList.remove('active');
        this.thumbnailTargets[index].classList.remove('active');
        this.desktopFooterTargets[index].classList.remove('active');
      }
    });

    this.countTarget.textContent = `${this.currentSlide + 1} / ${this.slideTargets.length}`;
  };

  thumbnailsWrapperWidthModifier = () => (window.innerWidth > grid.breakMd ? 1 : 2);

  onWindowResize = throttle(() => {
    this.setSliderHeight();
    this.displayActiveSlide();
  }, 66);
}
