import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['inlineWidget'];

  connect() {
    const calendlyHref = this.inlineWidgetTarget?.dataset?.href
      ? this.inlineWidgetTarget?.dataset?.href
      : 'https://calendly.com/d/dv3-tfw-97c/intro-call-with-startup-house';

    this.initCalendly(calendlyHref);
    setTimeout(() => this.setTitleForIframe(), 3000);
  }

  initCalendly = calendlyHref => {
    Calendly.initInlineWidget({
      url: `${calendlyHref}?hide_event_type_details=1?hide_landing_page_details=1`,
      parentElement: this.inlineWidgetTarget,
      utm: {
        utmCampaign: sessionStorage.getItem('utm_campaign') || '',
        utmSource: sessionStorage.getItem('utm_source') || '',
        utmMedium: sessionStorage.getItem('utm_medium') || '',
        utmContent: sessionStorage.getItem('utm_content') || '',
        utmTerm: sessionStorage.getItem('fbclid') || '',
      },
    });
  };

  setTitleForIframe = () => {
    const iframe = this.inlineWidgetTarget.querySelector('iframe');
    iframe.setAttribute('title', 'calendly');
  };
}
