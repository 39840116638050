import MicroModal from 'micromodal';
import CustomSelect from 'custom-select';
import 'custom-select/build/custom-select.css';
import imagesloaded from 'imagesloaded';
import FormController from 'shared/form_controller';

const TIME_TO_MODAL_DISPLAY = 8000;
const DECKS_URL = {
  'sxsw-early-stage-startup':
    'https://s3.eu-central-1.amazonaws.com/files.start-up.house/pre-seed-deck.pdf',
  'sxsw-seed-startup': 'https://s3.eu-central-1.amazonaws.com/files.start-up.house/seed-deck.pdf',
  'sxsw-series-a-scaleup':
    'https://s3.eu-central-1.amazonaws.com/files.start-up.house/series-a-deck.pdf',
};

export default class extends FormController {
  static captchaId = null;
  static targets = [
    'info',
    'name',
    'email',
    'role',
    'company',
    'agreement',
    'modalLoader',
    'downloadSection',
    'downloadButton',
    'guidebook',
    'captcha',
  ];

  connect() {
    this.initialModalSetup();
    this.fixDropdownStyles();

    // eslint-disable-next-line
    this.locale = window.location.pathname.split('/')[1];

    this.captchaId = window.hcaptcha.render('captcha-lead_magnet', {
      sitekey: this.data.get('siteKey'),
      theme: 'light',
    });
  }

  disconnect() {
    this.roleSelect.removeEventListener('change', this.setProperDropdownStyles);
  }

  removeHash = () => {
    window.history.replaceState(
      '',
      document.title,
      `${window.location.pathname}${window.location.search}`
    );
  };

  addHash = () => {
    window.history.replaceState(
      '',
      document.title,
      `${window.location.pathname}${window.location.search}#popup`
    );
  };

  initialModalSetup = () => {
    CustomSelect('#modal-form-role');
    CustomSelect('#modal-form-guidebook');
    MicroModal.init();
    imagesloaded(document, () => {
      this.element.classList.remove('invisible');
    });
    document.getElementById('modal-1').style.visibility = 'hidden';

    if (this.shouldModalBeDisplayed()) {
      document.getElementById('modal-1').style.visibility = 'visible';

      setTimeout(() => {
        MicroModal.show('modal-1', {
          onClose: (_, __, e) => {
            e.preventDefault();
            e.stopPropagation();
            this.removeHash();
          },
        });
        this.addHash();
        window.sessionStorage.isNewSession = true;
        window.localStorage.popupLockoutSpeciallyForDamian = 'no';
      }, TIME_TO_MODAL_DISPLAY);
    }

    if (window.location.hash === '#popup') {
      MicroModal.show('modal-1', {
        onClose: (_, __, e) => {
          e.preventDefault();
          e.stopPropagation();
          this.removeHash();
        },
      });
    }
  };

  shouldModalBeDisplayed = () => {
    const forbiddenPages = [
      'resources',
      'careers',
      'product-discovery',
      'case-studies',
      'sdh-labs',
      'startup-grind',
      'contact',
      'price-estimate',
      'early-stage-startup',
      'sustainable-development',
      'sustainable-business-checklist',
      'sustainable-canva',
      'services/workshops-and-prototyping',
      'series-a',
      'seed-startup',
      'user-interviews',
      'development-team/helsinki',
      'services',
    ];

    const isOnForbiddenPage = forbiddenPages.some(page => window.location.pathname.includes(page));

    return (
      !window.sessionStorage.isNewSession &&
      (!window.localStorage.popupLockoutSpeciallyForDamian ||
        window.localStorage.popupLockoutSpeciallyForDamian === 'no') &&
      !isOnForbiddenPage
    );
  };

  fixDropdownStyles = () => {
    this.roleSelect = this.roleTarget.querySelector('select');
    this.guidebookSelect = this.guidebookTarget.querySelector('select');
    this.roleTarget.querySelector('.custom-select-opener').setAttribute('aria-label', 'Role');
    this.guidebookTarget
      .querySelector('.custom-select-opener')
      .setAttribute('aria-label', 'Guidebook');
    this.roleTarget.querySelector('.custom-select-panel').setAttribute('aria-label', 'Role');
    this.guidebookTarget
      .querySelector('.custom-select-panel')
      .setAttribute('aria-label', 'Guidebook');

    this.roleSelect.addEventListener('change', event =>
      this.setProperDropdownStyles(event, this.roleTarget)
    );
    this.guidebookSelect.addEventListener('change', event =>
      this.setProperDropdownStyles(event, this.guidebookTarget)
    );

    if (this.roleSelect.value === '') {
      this.roleTarget.querySelector('.custom-select-opener > span').classList.add('is-disabled');
    }
    if (this.guidebookSelect.value === '') {
      this.guidebookTarget
        .querySelector('.custom-select-opener > span')
        .classList.add('is-disabled');
    }
    this.hideMockOption(this.roleTarget);
    this.hideMockOption(this.guidebookTarget);
  };

  hideMockOption = target => {
    Array.from(target.querySelectorAll('.custom-select-option')).find(
      option => option.dataset.value === ''
    ).style.display = 'none';
  };

  setProperDropdownStyles = (e, targetRef) => {
    if (e.target.value === '') {
      targetRef.querySelector('.custom-select-opener > span').classList.add('is-disabled');
    } else {
      targetRef.querySelector('.custom-select-opener > span').classList.remove('is-disabled');
      this.removeErrorMessage(targetRef);
    }
  };

  submit = e => {
    e.preventDefault();

    const isEmailValid = this.validateEmailInput(null, this.emailTarget, false);

    const isNameValid = this.validateInput(this.nameTarget, name => name !== '', '', false);

    const isRoleValid = this.validateInput(this.roleTarget, value => value !== '', '', false);

    const isGuidebookValid = this.validateInput(
      this.guidebookTarget,
      value => value !== '',
      '',
      false
    );

    const isCompanyValid = this.validateInput(this.companyTarget, text => text !== '', '', false);

    const isCheckboxChecked = this.validateCheckbox(this.agreementTarget, '');

    const token = window.hcaptcha.getResponse(this.captchaId);
    const isCaptchaSolved = this.validateCaptcha(this.captchaTarget, token.length !== 0);

    if (
      !isEmailValid ||
      !isNameValid ||
      !isRoleValid ||
      !isGuidebookValid ||
      !isCompanyValid ||
      !isCheckboxChecked ||
      !isCaptchaSolved
    ) {
      return;
    }

    const formData = new FormData();
    const selectedGuidebookValue = this.guidebookTarget.querySelector('select').value;
    this.downloadButtonTarget.href = DECKS_URL[selectedGuidebookValue];

    formData.append('full_name', this.nameTarget.querySelector('input').value);
    formData.append('email', this.emailTarget.querySelector('input').value);
    formData.append('role', this.roleTarget.querySelector('select').value);
    formData.append('company_name', this.companyTarget.querySelector('input').value);
    formData.append('terms', this.agreementTarget.querySelector('input').checked);
    formData.append('lead_source', 'SDH');
    formData.append('h-captcha-response', token);

    formData.append('guidebook', selectedGuidebookValue);

    const onSuccess = () => {
      this.infoTarget.classList.add('invisible');
      this.formTarget.classList.add('invisible');
      this.downloadSectionTarget.classList.remove('invisible');
      this.modalLoaderTarget.classList.remove('visible');
      this.handleDataLayer('Guide');
    };

    const onFail = () => {
      this.modalLoaderTarget.classList.remove('visible');
    };

    this.modalLoaderTarget.classList.add('visible');
    window.location.hash = '';
    this.submitForm('/api/leads', formData, { onSuccess, onFail }, false);
  };
}
