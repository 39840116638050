import { Controller } from 'stimulus';
import { randomElement } from 'shared/lib';
import { TimelineLite, Power1 } from 'gsap/all';
import { grid } from 'shared/constants';

const LOGO_CHANGE_INTERVAL = 2;

export default class extends Controller {
  static targets = ['logo', 'wrapper', 'inactiveLogoWrapper', 'inactiveOnMobile'];

  connect() {
    const observer = new IntersectionObserver(this.setup);
    observer.observe(this.element);
  }

  disconnect() {
    clearInterval(this.interval);
  }

  setup = (entries, observer) => {
    const isIntersecting = !!entries.find(entry => entry.isIntersecting);

    if (!isIntersecting) {
      return;
    }

    observer.unobserve(this.element);

    if (window.innerWidth <= grid.breakMd) {
      this.setupLogosForMobile();
    }
    this.loadLogoArrays();
    this.interval = setInterval(this.swapLogos, LOGO_CHANGE_INTERVAL * 1000);
  };

  setupLogosForMobile = () => {
    this.inactiveOnMobileTargets.forEach(logo => {
      this.inactiveLogoWrapperTarget.appendChild(logo);
      logo.querySelector('.logo-image').classList.add('inactive');
    });
  };

  swapLogos = () => {
    const activeLogo = randomElement(this.activeLogos);
    const inactiveLogo = randomElement(this.inactiveLogos);

    const tl = new TimelineLite();

    tl.to(activeLogo.logo, LOGO_CHANGE_INTERVAL * 0.2, {
      opacity: 0,
      ease: Power1.easeInOut,
      onComplete: () => {
        const activeSource = activeLogo.image.dataset.client;
        const inactiveSource = inactiveLogo.image.dataset.client;
        activeLogo.image.dataset.client = inactiveLogo.image.dataset.client;
        activeLogo.image.classList.remove(activeSource);
        activeLogo.image.classList.add(inactiveSource);

        inactiveLogo.image.dataset.client = activeSource;
        inactiveLogo.image.classList.remove(inactiveSource);
        inactiveLogo.image.classList.add(activeSource);
      },
    }).to(activeLogo.logo, LOGO_CHANGE_INTERVAL * 0.2, { opacity: 1 });
  };

  loadLogoArrays = () => {
    this.activeLogos = this.logoTargets
      .filter(logo => !logo.classList.contains('inactive'))
      .map(logo => ({ logo, image: logo.querySelector('.client-logo') }));

    this.inactiveLogos = this.logoTargets
      .filter(logo => logo.classList.contains('inactive'))
      .map(logo => ({ logo, image: logo.querySelector('.client-logo') }));
  };
}
