import { Controller } from 'stimulus';
import * as ScrollMagic from 'scrollmagic';
import { gsap, ScrollToPlugin, TweenMax, TimelineLite } from 'gsap/all';
import LazyLoad from 'vanilla-lazyload';
import is from 'is_js';
import throttle from 'lodash-es/throttle';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';

import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';

import { themes, grid } from './constants';
import { lightenDarkenColor } from './lib';

export default class extends Controller {
  static targets = [
    'section',
    'header',
    'headerBackground',
    'headerText',
    'headerArrow',
    'headerLogo',
    'headerButtonStripe',
    'headerButtonBackground',
    'headerLanguageSwitch',
    'headerButtonText',
    'headerSubnav',
    'cookiesBox',
    'loadOnScroll',
    'countryHelper',
    'projectIntro',
    'startupGrindLogo',
    'dropbtn',
    'dropdown',
    'langArrow',
    'langLabel',
  ];

  state = {
    isLoadOnScrollActive: false,
  };

  initialize() {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineLite);

    // check browser features
    if (this.shouldRedirectToNotSupportedPage()) {
      window.location = 'not-supported';
      return;
    }

    this.scrollController = new ScrollMagic.Controller();
    this.lazyImages = null;
    this.currentSection = null;

    // eslint-disable-next-line
    const plugins = [ScrollToPlugin];

    /* The following may yet come in handy, so I'll just comment it out */

    // if (this.hasCountryHelperTarget) {
    //   const { country, norway, emeanonorway } = this.countryHelperTarget.dataset;
    //   // eslint-disable-next-line
    //   console.log(
    //     `Country code: ${country}\n Is it norway? ${norway}\nIs it Europe and Africa and Mena, but not Norway? ${emeanonorway}\nIs it the rest of the world?  ${!norway &&
    //       !emeanonorway}`
    //   );
    // }
  }

  connect() {
    const isProjectPage =
      window.location.pathname.includes('case-studies') && this.hasProjectIntroTarget;

    if (isProjectPage) {
      this.updateHeaderColors(this.projectIntroTarget.dataset);
    }
    // check cookies
    const startUpCookie = this.getCookie('startup-cookie');
    if (!startUpCookie) {
      this.cookiesBoxTarget && this.cookiesBoxTarget.classList.remove('hide');
    }

    setTimeout(() => {
      if (this.hasHeaderTarget) {
        this.initializeSectionColors();
      }
    }, 10);

    let isReloaded = false;
    let isBackForward = false;

    if (window.performance) {
      const perfEntries = window.performance.getEntriesByType('navigation');
      isReloaded = perfEntries.find(entry => entry.type === 'reload');
      isBackForward = perfEntries.find(entry => entry.type === 'back_forward');
    }

    if (!this.isMobile() && !isReloaded && !isBackForward) {
      // TEMPORARILY DISABLED
      //   onScrollStop(this.snapToScreenHeight, 1000);
      this.setupLoadOnScroll();
    }

    // enable image lazy loading
    if (this.element.dataset.lazy) {
      this.lazyImages = new LazyLoad({
        threshold: 0,
        elements_selector: '.lazy-image',
      });
    }

    window.addEventListener('resize', this.toggleLoadOnScroll);
    window.addEventListener('keydown', this.handleFirstTab);

    this.saveUtmInSessionStorage();
  }

  disconnect() {
    window.removeEventListener('resize', this.toggleLoadOnScroll);
  }

  handleFirstTab = e => {
    if (e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing');
      window.removeEventListener('keydown', this.handleFirstTab);
      window.addEventListener('mousedown', this.handleMouseDownOnce);
    }
  };

  handleMouseDownOnce = () => {
    document.body.classList.remove('user-is-tabbing');
    window.removeEventListener('mousedown', this.handleMouseDownOnce);
    window.addEventListener('keydown', this.handleFirstTab);
  };

  initializeSectionColors() {
    this.sectionTargets.forEach(section => {
      // removed index for linter to stop screaming
      // First section - set header color accordingly
      const previousElement = section.previousElementSibling;
      if (previousElement && previousElement.classList.contains('header')) {
        this.updateHeaderColors(section.dataset);
      }

      if (!section.dataset.animate || this.isMobile()) {
        this.setStaticSection(section);
        return;
      }

      this.setAnimatedSection(section);

      // TEMPORARILY DISABLED
      // if (window.innerWidth >= grid.breakXl) {
      //   this.setupSnapScene(section, index);
      // }
    });
  }

  isMobile = () => window.innerWidth < grid.breakXl;

  setupLoadOnScroll = () => {
    this.loadOnScrollScenes = [];
    this.loadOnScrollTargets.forEach(target => {
      target.classList.add('scroll-not-loaded', 'scroll-transition');
      const scene = new ScrollMagic.Scene({
        triggerElement: target,
        triggerHook: 1,
      })
        .on('enter', () => target.classList.remove('scroll-not-loaded'))
        .addTo(this.scrollController);
      if (process.env.NODE_ENV === 'development') {
        scene.addIndicators({
          name: 'loadOnScroll',
          indent: 400,
        });
      }
      this.loadOnScrollScenes.push(scene);
    });
    this.state.isLoadOnScrollActive = true;
  };

  removeLoadOnScroll = () => {
    this.loadOnScrollTargets.forEach(target =>
      target.classList.remove('scroll-not-loaded', 'scroll-transition')
    );
    this.loadOnScrollScenes.forEach(scene => scene.destroy(true));
  };

  toggleLoadOnScroll = throttle(() => {
    if (this.isMobile() && this.state.isLoadOnScrollActive) {
      this.removeLoadOnScroll();
      this.state.isLoadOnScrollActive = false;
    } else if (!this.isMobile() && !this.state.isLoadOnScrollActive) {
      this.setupLoadOnScroll();
      this.state.isLoadOnScrollActive = true;
    }
  }, 66);

  setupSnapScene = (section, index) => {
    const scene = new ScrollMagic.Scene({
      triggerElement: section,
      triggerHook: 0.5,
    })
      .on('enter', () => {
        this.currentSection = section.dataset.snap ? section : null;
      })
      .on('leave', () => {
        const previousSection = this.sectionTargets[index - 1];
        this.currentSection = previousSection.dataset.snap ? previousSection : null;
      })
      .addTo(this.scrollController);

    if (process.env.NODE_ENV === 'development') {
      scene.addIndicators({
        name: `auto scroll ${section.dataset.snap ? 'SNAPPABLE' : 'NOT SNAPPABLE'}`,
        indent: 200,
      });
    }
  };

  snapToScreenHeight = () => {
    // When we enter a section without set snap, we don't want to snap to the previous one
    if (this.currentSection) {
      gsap.to(window, {
        duration: 0.5,
        scrollTo: this.currentSection,
      });
    }
  };

  chooseBackgroundColorByTheme = (theme, backgroundColor) => {
    if (theme === 'color' || theme === 'monoBaseWhite' || theme === 'monoBaseBlack') {
      return backgroundColor || themes[theme].backgroundColor;
    }

    return themes[theme].backgroundColor;
  };

  updateHeaderColors = (dataset, animated) => {
    const { theme, bgcolor, darkheader, transparentheader } = dataset;
    const headerTheme = darkheader === true || darkheader ? 'white' : theme;

    if (animated && !this.isMobile()) {
      this.headerTarget.classList.add('animate');
    } else {
      this.headerTarget.classList.remove('animate');
    }
    if (!this.headerBackgroundTarget.style) {
      return;
    }

    if (transparentheader) {
      this.headerBackgroundTarget.style.backgroundColor = 'transparent';
    } else {
      this.headerBackgroundTarget.style.backgroundColor = this.chooseBackgroundColorByTheme(
        theme,
        bgcolor
      );
    }

    if (window.location.pathname.includes('canva')) {
      this.headerBackgroundTarget.style.backgroundColor = '#000';
    }
    if (window.location.pathname === '/en/green-tech') {
      this.headerBackgroundTarget.style.backgroundColor = '#fff';
    }

    if (this.hasHeaderButtonBackgroundTarget) {
      this.headerButtonBackgroundTarget.querySelector('.button').style.backgroundColor =
        themes[theme].valueButtonBackgroundColor;
      this.headerButtonBackgroundTarget.querySelector('.icon-wrapper').style.color =
        themes[theme].valueButtonTextColor;
    }

    if (this.hasHeaderButtonTextTarget) {
      this.headerButtonTextTarget.style.color = themes[theme].valueButtonTextColor;
    }

    this.dropbtnTarget.style.backgroundColor = themes[theme].languageButtonBackgroundColor;
    this.dropbtnTarget.style.color = themes[theme].languageButtonTextColor;

    const langArrow = this.langArrowTarget;

    const langLabel = this.langLabelTarget;

    const langDropdown = this.dropdownTarget;
    langDropdown.style.backgroundColor = this.chooseBackgroundColorByTheme(theme, bgcolor);

    const languageButtons = Array.from(this.headerLanguageSwitchTarget.querySelectorAll('a'));

    languageButtons.forEach(button => {
      button.classList.contains('active')
        ? (button.nextElementSibling.style.opacity = 1)
        : (button.nextElementSibling.style.opacity = 0);
    });

    const activeLangButton = languageButtons.find(button => button.classList.contains('active'));
    const activeLangCheck = activeLangButton.nextElementSibling.children[0].children[0];

    const darkLabelBackground = [
      '#f5f5f5',
      '#e1e9f1',
      '#e3e6f1',
      '#FFD8DC',
      '#fad4d3',
      '#fffcef',
      '#DAE6FB',
    ];
    const lightShadowBackground = [
      '#000000',
      '#0066ff',
      '#151617',
      '#184e8f',
      '#232426',
      '#1a4f90',
    ];

    switch (theme) {
      case 'black':
        languageButtons.forEach(button => {
          button.classList.remove('lang-select-button--default');
          button.classList.add('lang-select-button--black');
          button.style.backgroundColor = themes[theme].languageButtonBackgroundColor;
          button.style.color = themes[theme].languageButtonTextColor;
        });

        activeLangCheck.classList.remove('stroke--black');
        activeLangCheck.classList.add('stroke--white');

        langDropdown.classList.add('light-shadow');
        langLabel.style.color = themes[theme].textColor;
        langArrow.style.backgroundColor = themes[theme].languageArrow;
        break;
      case 'color':
        if (darkLabelBackground.includes(bgcolor)) {
          languageButtons.forEach(button => {
            button.classList.add('lang-select-button--default');
            button.classList.remove('lang-select-button--black');
            button.style.color =
              button.classList.contains('active') || !bgcolor
                ? '#000000'
                : lightenDarkenColor(bgcolor, 30);
          });

          activeLangCheck.classList.remove('stroke--white');
          activeLangCheck.classList.add('stroke--black');

          langLabel.style.color = '#000000';
          langArrow.style.backgroundColor = '#636872';
        } else {
          languageButtons.forEach(button => {
            button.classList.remove('lang-select-button--default');
            button.classList.add('lang-select-button--black');
            button.style.color =
              button.classList.contains('active') || !bgcolor
                ? themes[theme].languageButtonTextColor
                : lightenDarkenColor(bgcolor, 30);
          });

          activeLangCheck.classList.remove('stroke--black');
          activeLangCheck.classList.add('stroke--white');

          langLabel.style.color = themes[theme].textColor;
          langArrow.style.backgroundColor = themes[theme].languageArrow;
        }

        if (lightShadowBackground.includes(bgcolor)) {
          langDropdown.classList.add('light-shadow');
        } else {
          langDropdown.classList.remove('light-shadow');
        }

        break;
      case 'monoBaseWhite':
        languageButtons.forEach(button => {
          button.classList.remove('lang-select-button--default');
          button.classList.add('lang-select-button--black');
          button.style.color =
            button.classList.contains('active') || !bgcolor
              ? themes[theme].languageButtonTextColor
              : lightenDarkenColor(bgcolor, 30);
        });

        activeLangCheck.classList.remove('stroke--black');
        activeLangCheck.classList.add('stroke--white');

        langDropdown.classList.add('light-shadow');
        langLabel.style.color = themes[theme].textColor;
        langArrow.style.backgroundColor = themes[theme].languageArrow;
        break;
      default:
        languageButtons.forEach(button => {
          button.classList.remove('lang-select-button--black');
          button.classList.add('lang-select-button--default');
          button.style.backgroundColor = themes[theme].languageButtonBackgroundColor;
          button.style.color = themes[theme].languageButtonTextColor;
        });
        activeLangCheck.classList.remove('stroke--white');
        activeLangCheck.classList.add('stroke--black');
        langDropdown.classList.remove('light-shadow');
        langLabel.style.color = themes[theme].textColor;
        langArrow.style.backgroundColor = themes[theme].languageArrow;
    }

    this.headerTextTargets.forEach(text => {
      text.style.color = themes[headerTheme].textColor;
      text.querySelectorAll('a').forEach(anchor => {
        if (theme === 'color' && !darkheader) {
          anchor.classList.add('blue-background');
        } else if (theme === 'monoBaseBlack') {
          anchor.classList.add('mono-black');
        } else if (theme === 'monoBaseWhite') {
          anchor.classList.remove('mono-black');
          anchor.classList.add('mono-white');
        } else {
          anchor.classList.remove('mono-black');
          anchor.classList.remove('blue-background');
          anchor.classList.remove('mono-white');
        }
      });
    });
    this.headerArrowTargets.forEach(arrow => {
      arrow.style.backgroundColor = themes[headerTheme].arrowColor;
    });
    this.headerLogoTarget.src = themes[headerTheme].logo;
    this.startupGrindLogoTarget.src = themes[headerTheme].logoStartupGrind;
    this.headerButtonStripeTargets.forEach(stripe => {
      stripe.style.backgroundColor = themes[headerTheme].textColor;
    });
    this.headerSubnavTargets.forEach(subnav => {
      const subnavThemes = ['white', 'gray', 'lightGray', 'monoBaseBlack'];
      if (!subnavThemes.includes(headerTheme)) {
        subnav.classList.remove('subnav--mono');
        subnav.classList.add('subnav--dark');
      } else if (headerTheme === 'monoBaseBlack') {
        subnav.classList.remove('subnav--dark');
        subnav.classList.add('subnav--mono');
      } else {
        subnav.classList.remove('subnav--mono');
        subnav.classList.remove('subnav--dark');
      }
    });
  };

  cleanSectionContentAnimations = section => {
    section.classList.remove('fade-in', 'fade-in-top', 'fade-in-bottom');
  };

  setStaticSectionHeaderAnimation = section => {
    const nextElement = section.nextElementSibling;
    const prevElement = section.previousElementSibling;

    if (!prevElement || !prevElement.dataset.animate || this.isMobile()) {
      new ScrollMagic.Scene({
        triggerElement: section,
        triggerHook: 0,
      })
        .on('enter', () => {
          // Enter by scrolling down
          this.updateSectionColors(section, section.dataset);
          this.updateHeaderColors(section.dataset);
        })
        .addTo(this.scrollController);
    }

    if (!nextElement || (nextElement.dataset.animate && !this.isMobile())) {
      return;
    }

    new ScrollMagic.Scene({
      triggerElement: nextElement,
      triggerHook: 0,
    })
      .on('leave', () => {
        // Enter by scrolling up (going back from next section)
        this.updateSectionColors(section, section.dataset);
        this.updateHeaderColors(section.dataset);
      })
      .addTo(this.scrollController);
  };

  updateSectionColors = (section, dataset) => {
    const { theme, bgcolor, color } = dataset;

    if (section.classList.contains('header')) {
      return;
    }

    section.style.backgroundColor = this.chooseBackgroundColorByTheme(theme, bgcolor);
    section.style.color = color || themes[theme].textColor;
  };

  setStaticSection = section => {
    if (section.dataset.bgcolor || !this.isMobile()) {
      this.updateSectionColors(section, section.dataset);
    }
    this.setStaticSectionHeaderAnimation(section);
  };

  updateSectionWithSiblings = (section, dataset) => {
    [
      section.previousElementSibling.previousElementSibling,
      section.previousElementSibling,
      section,
      section.nextElementSibling,
      section.nextElementSibling.nextElementSibling,
    ].forEach(sectionItem => {
      this.updateSectionColors(sectionItem, dataset);
    });
  };

  setAnimatedSection = section => {
    const nextElement = section.nextElementSibling;
    const prevElement = section.previousElementSibling;

    // Set animated sections initial colors to same as previous / next ones based on initial page scroll
    this.updateSectionColors(
      section,
      window.scrollY > section.getBoundingClientRect().bottom
        ? nextElement.dataset
        : prevElement.dataset
    );

    new ScrollMagic.Scene({
      triggerElement: section,
      triggerHook: 0.5,
    })
      .on('enter', () => {
        // Enter by scrolling down
        this.cleanSectionContentAnimations(prevElement);
        section.classList.add('fade-in', 'fade-in-top');

        this.updateSectionWithSiblings(section, section.dataset);
        this.updateHeaderColors(section.dataset, true);
      })
      .on('leave', () => {
        // Leave by scrolling up (entering previous section)
        this.cleanSectionContentAnimations(section);
        prevElement.classList.add('fade-in', 'fade-in-bottom');

        this.updateSectionWithSiblings(section, prevElement.dataset);
        this.updateHeaderColors(prevElement.dataset, true);
      })
      .addTo(this.scrollController);

    if (!nextElement) {
      return;
    }

    new ScrollMagic.Scene({
      triggerElement: nextElement,
      triggerHook: 0.5,
    })
      .on('leave', () => {
        // Enter by scrolling up (going back from next section)
        this.updateSectionWithSiblings(section, section.dataset);
        this.updateHeaderColors(section.dataset, true);
      })
      .on('enter', () => {
        // Leave by scrolling down (entering new section)
        this.updateSectionWithSiblings(section, nextElement.dataset);
        this.updateHeaderColors(nextElement.dataset, true);
      })
      .addTo(this.scrollController);
  };

  getCookie = name => {
    const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
    return v ? v[2] : null;
  };

  setCookie = (name, value, days) => {
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}`;
  };

  closeCookies = e => {
    e.preventDefault();

    this.setCookie('startup-cookie', 'showed', 30);
    this.cookiesBoxTarget && this.cookiesBoxTarget.classList.add('hide');
  };

  shouldRedirectToNotSupportedPage = () => {
    if (
      is.ie() ||
      'objectFit' in document.documentElement.style === false ||
      'fetch' in window === false ||
      !Array.from ||
      !window.Promise ||
      typeof Object.assign !== 'function'
    ) {
      return true;
    }

    return false;
  };

  saveUtmInSessionStorage = () => {
    const utmsNames = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_content',
      'source',
      'fbclid',
    ];
    const urlParams = new URLSearchParams(window.location.search);

    utmsNames.forEach(utmName => {
      const utmParam = urlParams.get(utmName);

      if (utmParam) {
        sessionStorage.setItem(utmName, utmParam);
      }
    });
  };
}
