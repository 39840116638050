import { Controller } from 'stimulus';
import { gsap, Power0, Power2, ScrollToPlugin, TimelineLite, TweenMax } from 'gsap/all';
import ScrollMagic from 'scrollmagic';
import { grid } from 'shared/constants';
import throttle from 'lodash-es/throttle';

import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';

export default class extends Controller {
  static targets = [
    'column',
    'container',
    'title',
    'header',
    'headerPart',
    'imagesWrapper',
    'imageContainer',
    'image',
    'scrollerDummy',
    'mask',
  ];

  state = {
    currentSlide: 0,
    prevSlide: null,
    numberOfSlides: 0,
    isAnimationOn: false,
    isSectionChanging: false,
    headerController: null,
    rotatorVersion: 'desktop',
    isRotatorLoading: true,
  };

  // eslint-disable-next-line class-methods-use-this
  initialize() {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineLite);
  }

  connect() {
    // eslint-disable-next-line
    const plugins = [ScrollToPlugin];

    this.state.numberOfSlides = this.titleTargets.length;
    if (window.innerWidth > grid.breakXl) {
      this.state.rotatorVersion = 'desktop';
      this.setupScrollController();
    } else {
      this.state.rotatorVersion = 'mobile';
      this.setupForMobile();
    }
    this.containerTarget.classList.add('loaded');
    window.addEventListener('resize', this.attachOrRemoveRotator);

    setTimeout(() => {
      this.state.isRotatorLoading = false;
    }, 2000);
  }

  disconnect() {
    window.removeEventListener('resize', this.attachOrRemoveRotator);
  }

  attachOrRemoveRotator = throttle(() => {
    if (window.innerWidth <= grid.breakXl) {
      if (this.state.rotatorVersion !== 'mobile') {
        this.state.rotatorVersion = 'mobile';
        this.scrollController.destroy(true);
        this.revertDesktopSetup();
        this.setupForMobile();
      }
    } else if (this.state.rotatorVersion !== 'desktop') {
      this.state.rotatorVersion = 'desktop';
      this.state.currentSlide = 0;

      this.revertMobileSetup();
      this.setupForDesktop();
      this.setupScrollController();
    }
  }, 66);

  setupScrollController = () => {
    this.setupForDesktop();
    this.scrollController = new ScrollMagic.Controller();
    this.setActiveSection();
    this.addPin();
    this.addScrolls();
  };

  setupForMobile = () =>
    this.headerTargets.forEach((header, index) => {
      header.prepend(this.imageTargets[index]);
      header.classList.add('active');
      this.imageTargets[index].classList.add('active');
    });

  setupForDesktop = () => {
    this.element.classList.add('desktop');
    this.headerTargets.forEach(header => header.classList.add('desktop'));
    this.containerTarget.classList.add('desktop');
    this.imageContainerTargets.forEach(container => container.classList.add('desktop'));
    this.maskTarget.classList.add('desktop');
    this.columnTargets.forEach(column => column.classList.add('desktop'));
    this.state.headerController = this.application.getControllerForElementAndIdentifier(
      document.querySelector('.header'),
      'header'
    );
  };

  revertDesktopSetup = () => {
    this.element.classList.remove('desktop');
    this.headerTargets.forEach(header => header.classList.remove('desktop'));
    this.containerTarget.classList.remove('desktop');
    this.imageContainerTargets.forEach(container => container.classList.remove('desktop'));
    this.maskTarget.classList.remove('desktop');
    this.columnTargets.forEach(column => column.classList.remove('desktop'));
  };

  revertMobileSetup = () => {
    this.headerTargets.forEach(header => header.classList.remove('active'));
    this.imageTargets.forEach(image => {
      this.imagesWrapperTarget.appendChild(image);
      image.classList.remove('active');
    });
  };

  selectSection = e => {
    const targetSlide = this.titleTargets.indexOf(e.target);
    gsap.to(window, {
      duration: 0.4,
      scrollTo: {
        y: this.scrollerDummyTargets[targetSlide],
        offsetY: window.innerHeight / 2 - 1,
      },
      onStart: () => {
        this.state.isSectionChanging = true;
      },
      onComplete: () => {
        this.state.isSectionChanging = false;
      },
    });

    setTimeout(this.setActiveSection, 500);
  };

  addPin = () => {
    const { numberOfSlides } = this.state;
    const scrollTween = gsap.to('.rotator .scroller-dummy-container', {
      duration: 1,
      y: `-${(numberOfSlides - 1) * 100 + 10}vh`,
      ease: Power0.easeNone,
      onUpdate: this.lockHeader,
      onComplete: this.unlockHeader,
      onReverseComplete: this.unlockHeader,
    });

    const scene = new ScrollMagic.Scene({
      duration: `${(numberOfSlides - 1) * 100 + 10}%`,
      triggerElement: '.rotator',
      triggerHook: 0,
    })
      .setPin('.rotator')
      .setTween(scrollTween)
      .addTo(this.scrollController);

    if (process.env.NODE_ENV === 'development') {
      scene.addIndicators();
    }
  };

  addScrolls = () => {
    this.scrollerDummyTargets
      .slice(0, this.scrollerDummyTargets.length - 1)
      .forEach((item, index) => {
        const scene = new ScrollMagic.Scene({
          triggerElement: item,
          triggerHook: 0,
          reverse: true,
        })
          .addTo(this.scrollController)
          .on('enter', this.toggleNextSection)
          .on('leave', this.togglePrevSection);

        if (process.env.NODE_ENV === 'development') {
          scene.addIndicators({
            name: `scroller-${index + 1}`,
            indent: 100,
          });
        }
      });
  };

  toggleNextSection = () => this.toggleSection(1);

  togglePrevSection = () => this.toggleSection(-1);

  toggleSection = dir => {
    const { currentSlide, numberOfSlides } = this.state;
    this.switchSection(currentSlide + dir);
    const leavingToTop = currentSlide === 1 && dir === -1;
    const leavingToBottom = currentSlide === numberOfSlides - 2 && dir === 1;

    if (this.state.isRotatorLoading || leavingToBottom || leavingToTop) {
      return;
    }

    this.scrollToMiddle(currentSlide + dir);
  };

  scrollToMiddle = target => {
    if (!this.state.isSectionChanging) {
      gsap.to(window, {
        duration: 0.4,
        scrollTo: {
          y: this.scrollerDummyTargets[target],
          offsetY: window.innerHeight / 2 - 10,
          autoKill: false,
        },
        ease: Power0.easeNone,
      });
    }
  };

  switchSection = target => {
    this.state.currentSlide = target;
    this.animateSectionChange();
  };

  animateSectionChange = () => {
    const { currentSlide } = this.state;
    if (this.imageTargets[currentSlide]) {
      this.maskTarget.style.backgroundColor = this.imageTargets[currentSlide].dataset.bgcolor;
    }
    this.setActiveSectionTitle();

    if (this.state.isAnimationOn) {
      setTimeout(this.setActiveSection, 500);
      return;
    }
    const tl = gsap.timeline();

    tl.fromTo(
      this.maskTarget,
      0.5,
      {
        y: window.innerHeight,
        opacity: 1,
      },
      {
        y: 0,
        onStart: () => {
          this.state.isAnimationOn = true;
        },
        onComplete: this.setActiveSection,
        ease: Power2.easeInOut,
      }
    )
      .to(this.maskTarget, 0.5, {
        y: -window.innerHeight,
        ease: Power2.easeInOut,
      })
      .to(this.maskTarget, 0, {
        opacity: 0,
        onComplete: this.setActiveSection,
      })
      .to(
        this.headerPartTargets,
        0.5,
        {
          yPercent: -100,
          ease: Power2.easeInOut,
        },
        0
      )
      .to(this.headerPartTargets, 0, { yPercent: 100 })
      .to(this.headerPartTargets, 0.5, {
        yPercent: 0,
        ease: Power2.easeInOut,
        onComplete: () => {
          this.state.isAnimationOn = false;
        },
      });
  };

  setActiveSection = () => {
    const { currentSlide } = this.state;
    this.setActiveSectionTitle();
    this.titleTargets.forEach((section, index) => {
      if (index === currentSlide) {
        this.headerTargets[index].classList.add('active');
        this.imageTargets[index].classList.add('active');
      } else {
        this.headerTargets[index].classList.remove('active');
        this.imageTargets[index].classList.remove('active');
      }
    });
  };

  setActiveSectionTitle = () => {
    const { currentSlide } = this.state;

    this.titleTargets.forEach((section, index) => {
      if (index === currentSlide) {
        section.classList.add('active');
      } else {
        section.classList.remove('active');
      }
    });
  };

  lockHeader = () => this.state.headerController.lock();

  unlockHeader = () => this.state.headerController.unlock();
}
