import { Controller } from 'stimulus';

import { ENDPOINTS } from '../../../shared/constants';

export default class extends Controller {
  connect() {
    this.setup();
  }

  async setup() {
    const data = await this.getData();
    this.updateTextContent(data);
  }

  getData = async () => {
    const res = await fetch(ENDPOINTS.companyValues);
    const data = await res.json();

    return {
      ...data,
    };
  };

  updateTextContent(data) {
    if (window.location.href.includes('about-us')) {
      this.updateAboutUsPage(data);
    }

    this.updateFooter(data);
  }

  updateAboutUsPage(data) {
    const elementsToUpdate = [
      {
        element: document.getElementById('yearsOnTheMarketTitle'),
        newContent: data.years_on_market,
      },
      {
        element: document.getElementById('projectsNumTitle'),
        newContent: data.projects,
      },
      {
        element: document.getElementById('numberOfEmployeesTitle'),
        newContent: data.employees,
      },
      {
        element: document.getElementById('numberOfEmployeesContent'),
        newContent: data.employees,
      },
    ];

    elementsToUpdate.forEach(element => {
      this.replaceTextContent(element.element, element.newContent);
    });
  }

  updateFooter(data) {
    const numberOfProjectsTitleEl = document.getElementById('projectsNumFooter');
    this.replaceTextContent(numberOfProjectsTitleEl, data.projects);
  }

  replaceTextContent = (element, newText) => {
    if (!element) {
      return;
    }

    element.textContent = newText;
  };
}
