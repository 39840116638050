import { Controller } from 'stimulus';
import { TimelineMax, Power0, TweenMax, TimelineLite } from 'gsap/all';
import ScrollMagic from 'scrollmagic';
import { grid } from 'shared/constants';

import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';

export default class extends Controller {
  static targets = ['mask', 'wrapper', 'loadOnScroll', 'loadTrigger'];

  // eslint-disable-next-line class-methods-use-this
  initialize() {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineLite);
  }

  connect() {
    if (window.innerWidth < grid.breakXl) {
      return;
    }
    this.addScrollController();
    this.addLoadScene();
  }

  addScrollController = () => {
    const scrollTween = new TimelineMax({ repeat: 0 });
    scrollTween.to(this.maskTarget, 1, {
      height: 0,
      ease: Power0.easeNone,
    });

    this.controller = new ScrollMagic.Controller();
    const arrowScene = new ScrollMagic.Scene({
      triggerElement: this.wrapperTarget,
      triggerHook: 0.8,
      duration: 1300,
    })
      .setTween(scrollTween)
      .addTo(this.controller);
    if (process.env.NODE_ENV === 'development') {
      arrowScene.addIndicators();
    }
  };

  addLoadScene = () => {
    this.loadOnScrollTargets.forEach((target, index) => {
      const tileScene = new ScrollMagic.Scene({
        triggerElement: this.loadTriggerTargets[index],
        triggerHook: 0.92,
      })
        .setClassToggle(target, 'load-on-scroll')
        .addTo(this.controller);
      if (process.env.NODE_ENV === 'development') {
        tileScene.addIndicators();
      }
    });
  };
}
