import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['inlineWidget'];

  connect() {
    this.initCalendly();
    setTimeout(() => this.setTitleForIframe(), 3000);
  }

  initCalendly = () => {
    Calendly.initInlineWidget({
      url: 'https://calendly.com/d/dv3-tfw-97c/intro-call-with-startup-house',
      parentElement: this.inlineWidgetTarget,
      utm: {
        utmCampaign: sessionStorage.getItem('utm_campaign') || '',
        utmSource: sessionStorage.getItem('utm_source') || '',
        utmMedium: sessionStorage.getItem('utm_medium') || '',
        utmContent: sessionStorage.getItem('utm_content') || '',
        utmTerm: sessionStorage.getItem('fbclid') || '',
      },
    });
  };

  setTitleForIframe = () => {
    const iframe = this.inlineWidgetTarget.querySelector('iframe');
    iframe.setAttribute('title', 'calendly');
  };
}
