import CustomSelect from 'custom-select';
import FormController from 'shared/form_controller';

const SUSTAINABLE_PDFS = {
  development:
    'https://startup-house.s3.eu-central-1.amazonaws.com/Does+development+for+a+cause+make+a+difference.pdf',
  checkList:
    'https://startup-house.s3.eu-central-1.amazonaws.com/Check+how+sustainable+your+company+really+is+-+the+checklist.pdf',
  canva: 'https://startup-house.s3.eu-central-1.amazonaws.com/IMPACT+MAP+CANVA.pdf',
};

export default class extends FormController {
  static targets = ['name', 'email', 'role', 'company', 'agreement', 'captcha'];

  initialize() {
    this.state = {
      isOpen: false,
    };
    this.modal = document.getElementById('download-report-modal');
    this.buttonToOpenModal = document.querySelectorAll('.open-download-report-modal');
    this.buttonToCloseModal = document.getElementById('close-modal');
  }

  connect() {
    CustomSelect('#download-report-modal-form-role');
    this.fixDropdownStyles();

    this.buttonToCloseModal.addEventListener('click', () => this.closeModal());
    this.buttonToOpenModal.forEach(e => {
      e.addEventListener('click', () => {
        this.openModal();
      });
    });

    window.hcaptcha.render('captcha-download_report_modal', {
      sitekey: this.data.get('siteKey'),
      theme: 'light',
    });
  }

  disconnect() {
    this.buttonToOpenModal.removeEventListener('click', this.openModal);
    this.buttonToCloseModal.removeEventListener('click', this.closeModal);
    this.roleSelect.removeEventListener('change', this.setProperDropdownStyles);
  }

  openModal = () => {
    this.state.isOpen = true;
    this.modal.classList.remove('invisible');
  };

  closeModal() {
    this.state.isOpen = false;
    this.modal.classList.add('invisible');
  }

  fixDropdownStyles = () => {
    this.roleSelect = this.roleTarget.querySelector('select');
    this.roleTarget.querySelector('.custom-select-opener').setAttribute('aria-label', 'Role');
    this.roleTarget.querySelector('.custom-select-panel').setAttribute('aria-label', 'Role');

    this.roleSelect.addEventListener('change', this.setProperDropdownStyles);

    if (this.roleSelect.value === '') {
      this.roleTarget.querySelector('.custom-select-opener > span').classList.add('is-disabled');
    }
    this.hideMockOption();
  };

  hideMockOption = () => {
    Array.from(this.roleTarget.querySelectorAll('.custom-select-option')).find(
      option => option.dataset.value === ''
    ).style.display = 'none';
  };

  setProperDropdownStyles = e => {
    if (e.target.value === '') {
      this.roleTarget.querySelector('.custom-select-opener > span').classList.add('is-disabled');
    } else {
      this.roleTarget.querySelector('.custom-select-opener > span').classList.remove('is-disabled');
      this.removeErrorMessage(this.roleTarget);
    }
  };

  getDownloadFileUrl = () => {
    switch (this.element.dataset.downloadfile) {
      case 'sustainable-development':
        return SUSTAINABLE_PDFS.development;

      case 'sustainable-business-checklist':
        return SUSTAINABLE_PDFS.checkList;
      case 'sustainable-canva':
        return SUSTAINABLE_PDFS.canva;
      default:
        return '';
    }
  };

  submit = e => {
    e.preventDefault();

    const isEmailValid = this.validateEmailInput(null, this.emailTarget, false);

    const isNameValid = this.validateInput(this.nameTarget, name => name !== '', '', false);

    const isRoleValid = this.validateInput(this.roleTarget, value => value !== '', '', false);

    const isCompanyValid = this.validateInput(this.companyTarget, text => text !== '', '', false);

    const isCheckboxChecked = this.validateCheckbox(this.agreementTarget, '');

    const token = window.hcaptcha.getResponse();
    const isCaptchaSolved = this.validateCaptcha(this.captchaTarget, token.length !== 0);

    if (
      !isEmailValid ||
      !isNameValid ||
      !isRoleValid ||
      !isCompanyValid ||
      !isCheckboxChecked ||
      !isCaptchaSolved
    ) {
      return;
    }

    const formData = new FormData();

    formData.append('full_name', this.nameTarget.querySelector('input').value);
    formData.append('email', this.emailTarget.querySelector('input').value);
    formData.append('role', this.roleTarget.querySelector('select').value);
    formData.append('company_name', this.companyTarget.querySelector('input').value);
    formData.append('terms', this.agreementTarget.querySelector('input').checked);
    formData.append('lead_source', 'SDH');
    formData.append('h-captcha-response', token);

    const onSuccess = () => {
      const url = this.getDownloadFileUrl();

      let isSafariAgent = navigator.userAgent.indexOf('Safari') > -1;
      const isChromeAgent = navigator.userAgent.indexOf('Chrome') > -1;

      // Discard Safari since it also matches Chrome
      if (isChromeAgent && isSafariAgent) isSafariAgent = false;

      if (isSafariAgent) {
        window.location.assign(url);
      } else {
        window.open(url, '_blank');
      }
      this.closeModal();
    };

    const onFail = () => {
      this.closeModal();
    };

    this.submitForm('/api/leads', formData, { onSuccess, onFail }, false);
  };
}
