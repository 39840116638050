import { Controller } from 'stimulus';
import dayjs from 'dayjs';

import { ENDPOINTS } from '../../../shared/constants';

export default class extends Controller {
  static targets = ['posts'];

  connect() {
    this.setup();
  }

  async setup() {
    const posts = await this.getData(2);
    this.displayPosts(posts);
  }

  getData = async numberOfPosts => {
    const res = await fetch(`${ENDPOINTS.latestPosts}?page=${numberOfPosts}`);

    return res.json();
  };

  preparePostMarkup = post =>
    `
        <a class="post" data-target="page.loadOnScroll" href="/en/blog/articles/${post.slug}">
          <img class="post__image" src="${post.image_thumb}" alt="${post.title}" />
          <div class="post__metadata">
            <p>${post.category_name}</p>
            <p>${dayjs(post.created_at).format('MMM DD, YYYY')}</p>
          </div>
          <h3 class="post__content">
            ${post.title}
          </h3>
        </a>
      `;

  displayPosts(posts) {
    const postsMarkup = posts.map(post => this.preparePostMarkup(post));

    this.postsTarget.innerHTML = postsMarkup.join('\n');
  }
}
