import { Controller } from 'stimulus';

import { toggleArrayElement } from 'shared/lib';

export default class extends Controller {
  static targets = ['wrapper', 'button'];

  initialize() {
    this.updateEvent = new Event('update');
  }

  connect() {
    this.isMultiple = this.wrapperTarget.dataset.multiple;
    this.options = [this.buttonTargets[0].dataset.value];
    this.buttonTargets[0].classList.add('selected');
  }

  toggleOption = e => {
    e.preventDefault();

    const option = e.target.dataset.value;

    if (this.isMultiple) {
      this.options = toggleArrayElement(this.options, option);
      e.target.classList.toggle('selected');
    } else {
      this.options = option;
      this.buttonTargets.forEach(button => button.classList.remove('selected'));
      e.target.classList.add('selected');
    }

    this.updateDOM();
  };

  updateDOM = () => {
    this.wrapperTarget.dataset.value = this.options;
    this.wrapperTarget.dispatchEvent(this.updateEvent);
  };
}
